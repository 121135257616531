<script>
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
import { ChevronLeftIcon, ChevronRightIcon } from "vue-feather-icons";

export default {
  extends: BaseVue,
  data() {
    return {
      categories: [],
      data: {
        data: []
      },
      sortBy: "",
      currCt: 0,
      heroImage: {},
    };
  },
  computed: {},
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  async mounted() {
    this.$set(this.$root, "page", this);
    this.getCategories();
    this.getPhotos();
    this.currCt = this.$route.params.kategori || 0;
    // $("#tabs").tabs({ active: this.$route.params.kategori });
    setTimeout(() => {
      SEMICOLON.initialize.lightbox();
    }, 500);
  },
  methods: {
    getCategories() {
      Gen.apirest("/gallery-category", {}, (err, resp) => {
        if (err) console.log(err);
        this.categories = resp.data;
      });
    },
    getPhotos() {
      const { kategori } = this.$route.params;
      const { sort, page } = this.$route.query;
      Gen.apirest(
        `/gallery-photo${kategori ? "/" + kategori : ""}`,
        { sort: sort, page: page },
        (err, resp) => {
          if (err) console.log(err);
          if (resp.data.data.length == 0) return this.$router.push({name: this.$route.name})
          this.data = resp.data;
          this.heroImage = resp.heroImage;
        }
      );
    },
    sortingGallery() {
      this.$router.push({
        name: "Gallery",
        query:
          this.sortBy &&
          Object.assign({}, this.$route.query, { sort: this.sortBy }),
        params: this.$route.params
      });
    },
    changePage(page = 1) {
      this.$router
        .push({
          query: Object.assign(Gen.clone(this.$route.query), { page: page }),
        })
        .catch(() => {});
      $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".tab-content").offset().top - 150,
          },
          500
      );
    },
  },
  watch: {
    $route() {
      let width = $(window).width()
      if(this.$route.query && width>991){
        setTimeout(()=>{
          $("#header").addClass("sticky-header")
          $("header-wrap").addClass("not-dark") 
        },500)
      }
      this.getPhotos();
      this.currCt = this.$route.params.kategori || 0;
      if (!this.$route.query.sort) {
        this.sortBy = "";
      }
    },
  },
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
      <span class="d-none position-absolute">{{ heroImage.alt_img }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Galeri</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Galeri
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
      <span class="d-none position-absolute">{{ heroImage.alt_img_mobile }}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Galeri</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active" aria-current="page"
                >
                  Galeri
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="content">
      <div class="content-wrap">
        <section class="section py-64">
          <div class="container" v-if="data.data.length > 0">
            <div class="tabs-bb clearfix" id="tabs">
              <ul class="tab-nav clearfix">
                <li :class="currCt == 0 ? 'ui-tabs-active' : ''">
                  <router-link itemprop="name" :to="{ name: 'Gallery' }" href="#tab-1"
                    >Semua Foto</router-link
                  >
                </li>
                <li
                  v-for="(category, index) in categories"
                  :key="category.agc_id"
                  :class="category.agc_slug == currCt ? 'ui-tabs-active' : ''"
                >
                  <router-link
                    itemprop="name"
                    :to="{ name: 'Gallery', params: { kategori: category.agc_slug } }"
                    :href="'#tab-' + (index + 2)"
                  >
                    {{ category.agc_category }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="main-list-filter my-4">
              <form action="" class="form-inline mb-4">
                <select
                  name="mainCat"
                  id="mainCat"
                  class="form-control custom-select mr-3"
                  v-model="sortBy"
                  @change="sortingGallery"
                >
                  <option value="">Sort by</option>
                  <option value="oldest">Terlama</option>
                  <option value="newest">Terbaru</option>
                </select>
              </form>
            </div>
            <div class="tab-container">
              <div class="tab-content clearfix" id="tab-1">
                <div
                  class="row justify-content-center gutter-8"
                  data-lightbox="gallery"
                >
                  <div
                    v-for="photo in data.data"
                    :key="photo.ag_id"
                    class="col-md-4 col-lg-3 col-6"
                  >
                    <a
                      :href="uploader(photo.ag_photo)"
                      data-lightbox="gallery-item"
                      ><img
                        itemprop="thumbnailUrl"
                        :src="uploader(photo.ag_photo)"
                        :alt="photo.ag_alt_img"
                        :title="photo.ag_alt_img"
                        class="gallery__img"
                    /></a>
                  </div>
                </div>

                <Pagination
                  :data="data"
                  :limit="2"
                  @pagination-change-page="changePage"
                  class="pagination pagination-transparent justify-content-center d-md-none d-md-flex"
                  itemprop="pagination"
                >
                  <chevron-left-icon slot="prev-nav"></chevron-left-icon>
                  <chevron-right-icon slot="next-nav"></chevron-right-icon>
                </Pagination>
              </div>
              <div class="tab-content clearfix" id="tab-2"></div>
              <div class="tab-content clearfix" id="tab-3"></div>
              <div class="tab-content clearfix" id="tab-4"></div>
            </div>
          </div>
          <div class="container text-center" v-else>
            <h3 class="fw-bold">Galeri belum tersedia</h3>
          </div>
        </section>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>

<style>
  .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: default;
  }
</style>
